import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import groupFollowingElementsByTag from "@micado-digital/react-ui/utils/groupFollowingElementsByTag";
import getJSXElement from "./getJSXElement";

import styles from "./Elements.styles";

const Elements = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	let { elements, lang } = pageData;

	// group downloads

	elements = groupFollowingElementsByTag(elements, "basic-download-group", "basic-download");

	// group dropdowns

	elements = groupFollowingElementsByTag(
		elements,
		"basic-dropdown-group",
		["basic-dropdown", "*"],
		"basic-dropdown",
		"basic-dropdown"
	);

	// exclude elements

	const excludeElementTags = ["basic-animation-textbox"];

	elements = elements.filter(element => {
		return excludeElementTags.indexOf(element.tag) === -1;
	});

	return (
		<div className={clsx(css.elements, "mco-elements")}>
			{elements?.map(element => {
				return getJSXElement(element, lang);
			})}
		</div>
	);
};

export default Elements;
