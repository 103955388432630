import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	animationWrapper: {
		color: theme.palette.common.white,
		height: "calc(90vh - 72px)",
		overflow: "hidden",
		position: "relative",
		width: "100%",
		"&:before": {
			background: "linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
			content: "''",
			display: "block",
			height: 300,
			left: 0,
			pointerEvents: "none",
			position: "absolute",
			right: 0,
			top: 0,
			zIndex: 10
		},
		[theme.breakpoints.up("md")]: {
			height: "100vh"
		}
	},
	animationWrapperShowBox: {
		height: "100vh"
	},
	animation: {
		height: "100%",
		position: "relative",
		"& .mco-animation-01": {
			position: "relative"
		},
		"& .mco-animation-01__image:before": {
			background: "rgba(0, 0, 0, 0.1)",
			content: "''",
			display: "block",
			inset: 0,
			pointerEvents: "none",
			position: "absolute",
			zIndex: 10
		},
		"& .mco-animation-01__image:after": {
			background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)",
			bottom: 0,
			content: "''",
			display: "block",
			height: 300,
			left: 0,
			pointerEvents: "none",
			position: "absolute",
			right: 0,
			zIndex: 1
		},
		"& .mco-animation-01__texts": {
			bottom: theme.spacing(8),
			display: "flex",
			flexDirection: "column",
			left: 0,
			padding: theme.spacing(2),
			right: 0,
			zIndex: 200,
			[theme.breakpoints.up(1500)]: {
				bottom: theme.spacing(10),
				left: "50%",
				transform: "translate(-50%, 0)",
				width: 1460
			}
		},
		"& .mco-animation-01__text--1 span": {
			background: theme.palette.secondary.main,
			display: "inline-block",
			padding: theme.spacing(0.5, 2),
			[theme.breakpoints.up("md")]: {
				paddingLeft: theme.spacing(5),
				paddingRight: theme.spacing(5)
			}
		},
		"& .mco-animation-01__text--2 span": {
			background: theme.palette.secondary.dark,
			display: "inline-block",
			padding: theme.spacing(1, 2),
			[theme.breakpoints.up("md")]: {
				paddingLeft: theme.spacing(5),
				paddingRight: theme.spacing(5)
			}
		},
		"& .mco-animation-01__bullet": {
			borderRadius: 0,
			height: 8,
			width: 8,
			"&.mco-animation-01__bullet--selected": {
				background: theme.palette.primary.main
			}
		}
	},
	scrollBottom: {
		alignItems: "center",
		background: theme.palette.primary.main,
		bottom: theme.spacing(2),
		color: theme.palette.text.secondary + " !important",
		cursor: "pointer",
		display: "flex",
		height: theme.spacing(5),
		justifyContent: "center",
		left: theme.spacing(2),
		position: "absolute",
		transition: "250ms background ease-out, 250ms color ease-out",
		width: theme.spacing(5),
		zIndex: 20
	},
	reservationBox: {
		bottom: 0,
		left: 0,
		position: "absolute",
		right: 0,
		zIndex: 600,
		[theme.breakpoints.up("md")]: {
			bottom: 80,
			left: "auto",
			maxWidth: 360,
			right: 0,
			width: "100%"
		},
		[theme.breakpoints.up(1460)]: {
			bottom: "50%",
			left: "50%",
			margin: "0 0 -221px 347px",
			right: "auto"
		}
	}
}));

export default styles;
