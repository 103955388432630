import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import UiContext from "@UiContext";
import useFindMenuGroup from "@micado-digital/react-ui/hooks/useFindMenuGroup";
import duplicateParentAsFirstChild from "@micado-digital/react-ui/utils/duplicateParentAsFirstChild";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Header.styles";

const Container = loadable(() => import("@Components/views/Container"));
const Logo = loadable(() => import("../Logo"));
const LangSelection = loadable(() => import("./LangSelection"));
const Hamburger = loadable(() => import("./Hamburger"));
const MobileMenu = loadable(() => import("./MobileMenu"));
const DesktopMenu = loadable(() => import("./DesktopMenu"));
const ButtonReserve = loadable(() => import("@Components/views/ButtonReserve"));

const Header = ({ lang }) => {
	const css = styles();
	const [ui] = useContext(UiContext);
	const { mobileMenuOpen } = ui;
	const menuItems = useFindMenuGroup("Hauptmenü");
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	duplicateParentAsFirstChild(menuItems, l("menu.overview"));

	return (
		<div className={clsx(css.header, "mco-view-component-header")}>
			<Container className={clsx(css.container, "mco-view-component-header__container")}>
				{isMobile && <Logo small />}
				{isDesktop && <Logo />}
				{isDesktop && <DesktopMenu items={menuItems} />}
				<LangSelection />
				{isDesktop && <ButtonReserve lang={lang} />}
				{isMobile && <Hamburger />}
				{isMobile && mobileMenuOpen && <MobileMenu items={menuItems} />}
			</Container>
		</div>
	);
};

export default Header;
