export const theme = {
	palette: {
		primary: {
			light: "#f6d55d",
			main: "#f2c10d",
			dark: "#ac8909"
		},
		secondary: {
			light: "#54c7ec",
			main: "#00abe3",
			dark: "#0079a1"
		},
		background: {
			default: "#20252d",
			header: "#181a1b",
			menu: "#181a1b",
			footer: "#1d2027",
			light: "#54c7ec",
			dark: "#000000"
		},
		text: {
			primary: "#FFFFFF",
			secondary: "#1f1906",
			disabled: "#bfbfbf",
			hint: "#bfbfbf"
		},
		grey: {
			50: "#f7f7f8",
			100: "#e3e5e8",
			200: "#cdd0d6",
			300: "#abb0ba",
			400: "#8f96a3",
			500: "#737b8c",
			600: "#5c6370",
			700: "#454a54",
			800: "#2e3138",
			900: "#17191c",
			A100: "#cdd0d6",
			A200: "#abb0ba",
			A400: "#2e3138",
			A700: "#5c6370"
		},
		common: {
			white: "#ffffff",
			black: "#000000"
		},
		info: {
			light: "#77b6da",
			main: "#0175b9",
			dark: "#01446c"
		},
		success: {
			light: "#a4bf7a",
			main: "#70992e",
			dark: "#477301"
		},
		warning: {
			light: "#ffde77",
			main: "#ffc105",
			dark: "#d19e05"
		},
		error: {
			light: "#f08080",
			main: "#d50b0b",
			dark: "#8b0101"
		},
		divider: "#cccccc"
	},
	typography: {
		htmlFontSize: 16,
		fontFamily: "Open Sans",
		fontSize: 16,
		fontWeightLight: 400,
		fontWeightRegular: 400,
		fontWeightMedium: 700,
		fontWeightBold: 700,
		"display-large": {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: "clamp(34px, 5vw, 70px)",
			letterSpacing: -0.56,
			lineHeight: 1.2
		},
		"display-medium": {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 48,
			letterSpacing: 0,
			lineHeight: 1.3125
		},
		"display-small": {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 42,
			letterSpacing: 0,
			lineHeight: 1.30952380952381
		},
		"headline-large": {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 36,
			letterSpacing: 0,
			lineHeight: 1.305555555555556
		},
		"headline-medium": {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 28,
			letterSpacing: 0,
			lineHeight: 1.321428571428571
		},
		"headline-small": {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 24,
			letterSpacing: 0,
			lineHeight: 1.333333333333333
		},
		"title-large": {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 24,
			letterSpacing: 0,
			lineHeight: 1.625
		},
		"title-medium": {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 20,
			letterSpacing: 0,
			lineHeight: 1.8
		},
		"title-small": {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 16,
			letterSpacing: 0.8,
			textTransform: "uppercase",
			lineHeight: 1.3125
		},
		"body-large": {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: 0.2,
			lineHeight: 1.8
		},
		"body-medium": {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0.18,
			lineHeight: 1.611111111111111
		},
		"body-small": {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 14,
			letterSpacing: 0.7000000000000001,
			lineHeight: 1.642857142857143
		},
		"label-large": {
			fontFamily: "'Titillium Web'",
			fontWeight: 700,
			fontSize: 20,
			letterSpacing: 1,
			textTransform: "uppercase",
			lineHeight: 1
		},
		"label-medium": {
			fontFamily: "'Titillium Web'",
			fontWeight: 700,
			fontSize: 16,
			letterSpacing: 0.8,
			textTransform: "uppercase",
			lineHeight: "21px"
		},
		"label-small": {
			fontFamily: "'Titillium Web'",
			fontWeight: 700,
			fontSize: 14,
			letterSpacing: 0.7000000000000001,
			textTransform: "uppercase",
			lineHeight: 1.357142857142857
		},
		h1: {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 56,
			letterSpacing: -0.56,
			lineHeight: 1.303571428571429
		},
		h2: {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 48,
			letterSpacing: 0,
			lineHeight: 1.3125
		},
		h3: {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 42,
			letterSpacing: 0,
			lineHeight: 1.30952380952381
		},
		h4: {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 36,
			letterSpacing: 0,
			lineHeight: "47px"
		},
		h5: {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 28,
			letterSpacing: 0,
			lineHeight: 1.321428571428571
		},
		h6: {
			fontFamily: "'Titillium Web'",
			fontWeight: 600,
			fontSize: 24,
			letterSpacing: 0,
			lineHeight: 1.333333333333333
		},
		body1: {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0.18,
			lineHeight: 1.6
		},
		subtitle1: {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: 0.2,
			lineHeight: 1.8
		},
		subtitle2: {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0.18,
			lineHeight: 1.611111111111111
		},
		overline: {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 14,
			letterSpacing: 0.7000000000000001,
			lineHeight: 1.642857142857143
		},
		"button-large": {
			fontFamily: "'Titillium Web'",
			fontWeight: 700,
			fontSize: 20,
			letterSpacing: 1,
			textTransform: "uppercase",
			lineHeight: 1
		},
		"button-medium": {
			fontFamily: "'Titillium Web'",
			fontWeight: 700,
			fontSize: 16,
			letterSpacing: 0.8,
			textTransform: "uppercase",
			lineHeight: 1.3125
		},
		"button-small": {
			fontFamily: "'Titillium Web'",
			fontWeight: 700,
			fontSize: 14,
			letterSpacing: 0.7000000000000001,
			textTransform: "uppercase",
			lineHeight: 1.357142857142857
		}
	},
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiButton: {
			root: {
				borderRadius: 0,
				boxShadow: "none !important",
				minHeight: 48,
				textTransform: "uppercase"
			},
			sizeLarge: {
				minHeight: 48
			},
			sizeSmall: {
				fontSize: 14,
				minHeight: 40
			},
			containedPrimary: {
				boxShadow:
					"inset 0 0 10px 0 #f2c10d, 0 0 4px 0 #f2c10f, inset 0 0 0 1px #fff !important"
			},
			containedSecondary: {
				boxShadow:
					"inset 0 0 10px 0 #01abe3, 0 0 4px 0 #01abe3, inset 0 0 0 1px #fff !important"
			}
		},
		MuiInputBase: {
			root: {
				fontWeight: "700 !important"
			}
		}
	},
	props: {
		MuiButton: {
			disableElevation: true
		},
		MuiButtonGroup: {
			disableElevation: true
		}
	}
};
