import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	elements: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(8),
		position: "relative",
		scrollMarginTop: "72px",
		zIndex: 1,
		"& > *:first-child:not(.mco-collapsing)": {
			marginTop: theme.spacing(4)
		},
		"& > *:last-child:not(.mco-collapsing)": {
			marginBottom: theme.spacing(4)
		},
		"& > *:last-child.mco-view-component-container-basic-html": {
			marginBottom: theme.spacing(0)
		},
		[theme.breakpoints.up("md")]: {
			"& > *:first-child:not(.mco-collapsing)": {
				marginTop: theme.spacing(12)
			},
			"& > *:last-child:not(.mco-collapsing)": {
				marginBottom: theme.spacing(10.5)
			},
			"& > *:last-child.mco-view-component-container-basic-html": {
				marginBottom: theme.spacing(0)
			}
		}
	}
}));

export default styles;
