import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	header: {
		height: 72,
		left: 0,
		position: "fixed",
		right: 0,
		transition: "background 400ms ease-out",
		top: 0,
		zIndex: theme.zIndex.modal - 2,
		"& .mco-view-component-header-lang-selection": {
			alignItems: "center",
			display: "flex",
			height: 72,
			justifyContent: "center",
			justifySelf: "flex-end",
			margin: theme.spacing(0, 2, 0, 0),
			width: theme.spacing(6),
			[theme.breakpoints.up("md")]: {
				padding: theme.spacing(0, 0, 0, 2),
				width: theme.spacing(8)
			}
		},
		"& .mco-view-component-button-reserve": {
			height: "100%",
			maxHeight: 72
		},
		"& .mco-view-component-button-reserve button": {
			height: "100%",
			maxHeight: 72
		},
		[theme.breakpoints.up(1100)]: {
			"& .mco-view-component-logo a": {
				padding: theme.spacing(3, 0)
			}
		}
	},
	container: {
		alignItems: "center",
		display: "grid",
		gridTemplateColumns: "60px 1fr 72px",
		height: "100%",
		paddingRight: "0 !important",
		[theme.breakpoints.up("md")]: {
			gridTemplateColumns: "203px 1fr 80px auto"
		},
		[theme.breakpoints.up(1100)]: {
			alignItems: "flex-start",
			gridTemplateColumns: "251px 1fr 80px auto"
		},
		[theme.breakpoints.up(1460)]: {
			paddingRight: "24px !important"
		}
	}
}));

export default styles;
