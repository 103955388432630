import React, { useContext } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import loadable from "@loadable/component";
import { motion } from "framer-motion";
import PageContext from "@PageContext";
import UiContext from "@UiContext";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import styles from "./Animation.styles";

const ReactAnimation = loadable(() =>
	import("@micado-digital/react-animation/ReactAnimation")
);
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const ReservationBox = loadable(() => import("../ReservationBox"));

const Animation = () => {
	const { REACT_APP_PATH } = process.env;
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { animationID } = pageData;
	const [ui] = useContext(UiContext);
	const { showReservationBox } = ui;
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	const handleScrollToContent = () => {
		const $elements = document.querySelector(".mco-elements");

		if ($elements) {
			$elements?.scrollIntoView({
				behavior: "smooth"
			});
		}
	};

	if (isEmpty(pageData)) return null;

	return (
		<div
			className={clsx(css.animationWrapper, "mco-view-component-header-animation-wrapper", {
				[css.animationWrapperShowBox]: showReservationBox
			})}
		>
			<div className={clsx(css.animation, "mco-view-component-header-animation")}>
				<ReactAnimation
					api={`${REACT_APP_PATH}/animationV3.json.api`}
					id={animationID}
					mediaFormats={{
						xs: "animation-mobile",
						sm: "animation-hd",
						lg: "animation"
					}}
					prevIcon={<ReactSVGIcon src="/img/icons/arrow-left.svg" size={36} />}
					nextIcon={<ReactSVGIcon src="/img/icons/arrow-right.svg" size={36} />}
					showArrows
					showBullets
					textComponents={{
						text1: "p",
						text2: "p"
					}}
					textVariants={{
						text1: {
							xs: "title-medium",
							sm: "display-small",
							lg: "display-large"
						},
						text2: {
							xs: "titel-small",
							sm: "title-large",
							lg: "display-small"
						}
					}}
					webpFallback
				/>
			</div>
			<motion.div
				animate={{ opacity: 1, x: 0 }}
				className={clsx(
					css.scrollBottom,
					"mco-view-component-header-animation__scroll-bottom"
				)}
				initial={{ opacity: 0, x: -64 }}
				onClick={handleScrollToContent}
				transition={{
					delay: 2,
					duration: 0.45,
					ease: [0, 0.71, 0.2, 1.01]
				}}
			>
				<ReactSVGIcon src="/img/icons/arrow-down.svg" />
			</motion.div>
			{(showReservationBox || isDesktop) && (
				<motion.div
					animate={{
						opacity: 1,
						x: 0,
						y: 0
					}}
					className={clsx(
						css.reservationBox,
						"mco-view-component-header-animation__reservation-box"
					)}
					initial={{
						opacity: 0,
						x: isDesktop ? 72 : 0,
						y: !isDesktop ? 72 : 0
					}}
					transition={{
						ease: [0, 0.71, 0.2, 1.01],
						delay: isDesktop ? 1.8 : 0.45,
						duration: 0.45
					}}
				>
					<ReservationBox mode="animation" />
				</motion.div>
			)}
		</div>
	);
};

export default Animation;
