import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import AccountData from "@micado-digital/react-ui/components/AccountData";
import UiContext from "@UiContext";
import PageTransition from "@micado-digital/react-ui/components/PageTransition";
import Locals from "@micado-digital/react-ui/components/Locals";
import useIsCurrentLocation from "@micado-digital/react-ui/hooks/useIsCurrentLocation";
import useTheme from "@material-ui/core/styles/useTheme";
import Core from "./Core";
import Header from "@Components/views/Header";
import Animation from "@Components/views/Animation";
import Default from "./Default";

import styles from "./Views.styles";

const ButtonReserve = loadable(() => import("@Components/views/ButtonReserve"));
const Footer = loadable(() => import("@Components/views/Footer"));

const Views = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { authorID, lang, layoutTag, layoutVariant } = pageData || {};
	const [ui, setUi] = useContext(UiContext);
	const { desktopMenuOpen, mobileMenuOpen, showReservationBox } = ui;
	const theme = useTheme();
	const currentLocation = useIsCurrentLocation();
	const [isScrolling, setIsScrolling] = useState(false);
	const hasAnimation =
		layoutVariant !== "no-animation" &&
		layoutVariant !== "reservation" &&
		layoutVariant !== "appartement";

	const {
		palette: {
			primary: { light: primaryMainColor }
		}
	} = theme;

	useEffect(() => {
		if (mobileMenuOpen) {
			setUi(prevState => ({ ...prevState, mobileMenuOpen: false }));
		}
		if (desktopMenuOpen) {
			setUi(prevState => ({ ...prevState, desktopMenuOpen: false }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [document.URL]);

	const handleScroll = () => {
		const position = window.pageYOffset;

		if (position > 0) {
			setIsScrolling(true);
		} else {
			setIsScrolling(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll, { passive: true });

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	if (!lang) return null;

	return (
		<WebProfile>
			<AccountData authorID={authorID}>
				<Locals url={`/locals/${lang || "de"}.json`}>
					<div
						className={clsx(css.page, "mco-page", {
							[css.pageScroll]: isScrolling || desktopMenuOpen,
							"mco-page--scroll": isScrolling || desktopMenuOpen,
							[css.pageNoAnimation]: !hasAnimation,
							"mco-page--no-animation": !hasAnimation,
							"mco-page--reservation": layoutVariant === "reservation"
						})}
					>
						<Header lang={lang} />
						{hasAnimation && <Animation />}
						{!showReservationBox && layoutVariant !== "reservation" && (
							<ButtonReserve lang={lang} toggleBox />
						)}
						{layoutTag === "default" && currentLocation && <Default />}
						{currentLocation && <Footer lang={lang} />}
						<PageTransition color={primaryMainColor} position="top" />
					</div>
					<Core />
				</Locals>
			</AccountData>
		</WebProfile>
	);
};

export default Views;
