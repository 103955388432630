import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import createTheme from "@material-ui/core/styles/createTheme";
import { alpha } from "@material-ui/core/styles/";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { theme as customTheme } from "./themes/project";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import Ui from "@micado-digital/react-ui/components/Ui";
import UserData from "@micado-digital/react-ui/components/UserData";
import CSSProperties from "./utils/CSSProperties";
import CssBaseline from "@material-ui/core/CssBaseline";
import ReservationContext from "./context/ReservationContext";
import Views from "./views";

import "./css/index.css";

const theme = createTheme(customTheme);

const App = () => {
	const [reservationData, setReservationData] = useState({});

	useEffect(() => {
		CSSProperties(theme, alpha);
	}, []);

	// read reservation data from storage

	useEffect(() => {
		const storage = sessionStorage.getItem("skirent-reservation");

		if (storage) {
			setReservationData(JSON.parse(storage));
		}
	}, []);

	// set reservation data to storage

	useEffect(() => {
		if (isEmpty(reservationData)) {
			return;
		}

		if (reservationData.delete) {
			sessionStorage.removeItem("skirent-reservation");
			return;
		}

		sessionStorage.setItem("skirent-reservation", JSON.stringify(reservationData));
	}, [reservationData]);

	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<></>}>
				<CssBaseline />
				<BrowserRouter>
					<Ui>
						<WebPageData>
							<UserData>
								<ReservationContext.Provider value={[reservationData, setReservationData]}>
									<Routes>
										<Route path="/*" element={<Views />} />
									</Routes>
								</ReservationContext.Provider>
							</UserData>
						</WebPageData>
					</Ui>
				</BrowserRouter>
			</Suspense>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));
