import React from "react";
import loadable from "@loadable/component";
import clsx from "clsx";

const Connector = loadable(() => import("@micado-digital/react-form/Connector"));
const Container = loadable(() => import("@Components/views/Container"));
const Form = loadable(() => import("@micado-digital/react-form"));
const ReactCopyrights01 = loadable(() =>
	import("@micado-digital/react-copyrights/ReactCopyrights01")
);
const ReactDropDown01 = loadable(() =>
	import("@micado-digital/react-dropdown/ReactDropDown01")
);
const ReactFile01 = loadable(() => import("@micado-digital/react-file/ReactFile01"));
const ReactGallery01 = loadable(() => import("@micado-digital/react-gallery/ReactGallery01"));
const ReactHeadline01 = loadable(() =>
	import("@micado-digital/react-headline/ReactHeadline01")
);
const ReactHTML = loadable(() => import("@micado-digital/react-html/ReactHTML"));
const ImageText = loadable(() => import("@Components/Elements/ImageText"));
const ReactList01 = loadable(() => import("@micado-digital/react-list/ReactList01"));
const ReactReferences01 = loadable(() =>
	import("@micado-digital/react-references/ReactReferences01")
);
const ReactSingleImage01 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage01")
);
const ReactTable01 = loadable(() => import("@micado-digital/react-table/ReactTable01"));
const ReactText01 = loadable(() => import("@micado-digital/react-text/ReactText01"));
const ReactVideo01 = loadable(() => import("@micado-digital/react-video/ReactVideo01"));
const ReactLegacy = loadable(() => import("@micado-digital/react-legacy/ReactLegacy"));
const OnlineReservation = loadable(() => import("@Components/Elements/OnlineReservation"));
const CartDelete = loadable(() => import("@Components/Elements/OnlineReservation/CartDelete"));
const FormCart = loadable(() => import("@Components/Elements/OnlineReservation/FormCart"));
const TeaserGroup = loadable(() => import("@Components/Elements/TeaserGroup"));
const Teaser = loadable(() => import("@Components/Elements/TeaserGroup/Teaser"));

const getJSXElement = (element, lang) => {
	const { REACT_APP_PATH } = process.env;
	const { id, tag } = element;

	switch (tag) {
		case "basic-headline": {
			const { text, variant = 0 } = element;

			let config = {};

			switch (variant) {
				case 0: {
					config = {
						component: "h1",
						variant: "display-large"
					};
					break;
				}
				case 1: {
					config = {
						component: "h2",
						variant: "display-medium"
					};
					break;
				}
				case 2: {
					config = {
						component: "h3",
						variant: "display-small"
					};
					break;
				}
				case 3: {
					config = {
						component: "h4",
						variant: "headline-large"
					};
					break;
				}
				case 4: {
					config = {
						component: "h5",
						variant: "headline-medium"
					};
					break;
				}
				case 5: {
					config = {
						component: "h6",
						variant: "headline-small"
					};
					break;
				}
				case 6: {
					config = {
						component: "p",
						variant: "title-small"
					};
					break;
				}
				default: {
					break;
				}
			}

			return (
				<Container key={id} tag={tag} variant={variant + 1}>
					<ReactHeadline01
						component={config?.component}
						text={text}
						variant={config?.variant}
						variants={config?.variants}
					/>
				</Container>
			);
		}

		case "basic-text": {
			const { text, variant } = element;

			return (
				<Container
					className={clsx({
						"mco-view-component-container-basic-text-variant-1": variant === 1
					})}
					key={id}
					tag={tag}
				>
					<ReactText01 text={text} />
				</Container>
			);
		}

		case "basic-list": {
			const { textcontent, textcontent: { items = [] } = {}, title } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactList01
						headlineVariant="headline-medium"
						textcontent={textcontent}
						title={title}
						variant={items.length > 6 ? 0 : 1}
					/>
				</Container>
			);
		}

		case "basic-table": {
			const { addition, title, textcontent } = element;

			if (!textcontent?.items) {
				return;
			}

			const newTableArray = [];

			for (const entries of textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});

				newTableArray.push(columns);
			}

			return (
				<Container key={id} tag={tag}>
					<ReactTable01
						addition={addition}
						headlineVariant="headline-medium"
						items={newTableArray}
						title={title}
					/>
				</Container>
			);
		}

		case "basic-links": {
			const { elements, title } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactReferences01
						elements={elements}
						hasIcon={false}
						headlineVariant="headline-medium"
						linkVariant="body-large"
						title={title}
					/>
				</Container>
			);
		}

		case "basic-video": {
			const { media, title, text } = element;

			if (!media) {
				return;
			}

			return (
				<Container key={id} tag={tag}>
					<ReactVideo01
						additionVariant="body-small"
						titleVariant="headline-large"
						media={media && media}
						mediaFormats={{
							xs: "video-mobile",
							sm: "video"
						}}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-html": {
			const { text } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactHTML key={id} html={text} />
				</Container>
			);
		}

		case "basic-download-group": {
			const { children } = element;

			return (
				<Container key={id} tag={tag}>
					{children?.map(item => getJSXElement(item))}
				</Container>
			);
		}

		case "basic-download": {
			const { media, title } = element;

			if (!media) {
				return;
			}

			return (
				<Container key={id} tag={tag}>
					<ReactFile01 media={media} title={title} />
				</Container>
			);
		}

		case "basic-copyrights": {
			return (
				<Container key={id} tag={tag}>
					<ReactCopyrights01 />
				</Container>
			);
		}

		case "basic-imprint": {
			return (
				<Container key={id} tag={tag}>
					<ReactLegacy lang={lang} logoIdentifier="logo" />
				</Container>
			);
		}

		case "basic-dropdown-group": {
			const { children } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactDropDown01 option={0} title={element?.children?.[0]?.title}>
						<>
							{children
								?.filter(item => item.tag !== "basic-dropdown")
								?.map(item => getJSXElement(item))}
						</>
					</ReactDropDown01>
				</Container>
			);
		}

		case "basic-singleimage": {
			const { addition, media, option2, reference, text } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactSingleImage01
						addition={addition}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: "singleimage-mobile",
							sm: "singleimage-medium",
							md: "singleimage-large",
							lg: "singleimage"
						}}
						mediaPath={REACT_APP_PATH}
						option2={option2}
						reference={reference}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-teaser": {
			const { teasergroup: { items } = {}, title } = element;

			return (
				<Container key={id} tag={tag}>
					<TeaserGroup items={items} lang={lang} title={title} />
				</Container>
			);
		}

		case "basic-singleteaser": {
			const {
				teaser: { link, media, text, title } = {},
				text: overrideText,
				title: overrideTitle
			} = element;

			return (
				<Container key={id} tag={tag}>
					<Teaser
						lang={lang}
						link={link}
						media={media}
						text={overrideText ? overrideText : text}
						title={overrideTitle ? overrideTitle : title}
					/>
				</Container>
			);
		}

		case "basic-imagetext": {
			const { addition: alt, addition2: headline, media, text, title } = element;

			return (
				<Container key={id} tag={tag}>
					<ImageText alt={alt} headline={headline} media={media} text={text} title={title} />
				</Container>
			);
		}

		case "basic-gallery": {
			const { elements } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactGallery01
						elements={elements}
						mediaFormats={{
							xs: "gallery-thumbnail"
						}}
						overlayMediaFormats={{
							xs: "gallery"
						}}
					/>
				</Container>
			);
		}

		case "basic-form": {
			const { elements } = element;

			return (
				<Container key={id} tag={tag}>
					<Form action={`${REACT_APP_PATH}/submitform.json.api`} method="POST">
						<Connector
							additionalFormElements={[
								{
									tag: "basic-form-cart",
									component: <FormCart lang={lang} />
								}
							]}
							formElements={elements}
							formID={id}
						/>
					</Form>
				</Container>
			);
		}

		case "extension-online-reservation": {
			return (
				<Container key={id} tag={tag}>
					<OnlineReservation lang={lang} />
				</Container>
			);
		}

		case "extension-cart-delete": {
			return <CartDelete key={id} />;
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.warn("missing element:", element);
			}
			return null;
	}
};

export default getJSXElement;
